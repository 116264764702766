import React from 'react';

export default function ServicesHead(props) {
    return (
        <header className="services-head">
            <div className='services-head-img--ctn'></div>
            <div className="services-head-overlay"></div>
            <h1 className="services-head-title">{props.title}</h1>
        </header>
    )
}
