import React, { Component } from 'react';
import map from '../../assests/images/images/browse-location-img.png';
import Icon from '../../svgs';
import bahrain from '../../assests/images/images/bahrain.jpg';
import belarus from '../../assests/images/images/belarus.jpg';
import belgium from '../../assests/images/images/belgium.webp';
import dubai from '../../assests/images/images/dubai.jpg';
import france from '../../assests/images/images/france.jpg';
import germany from '../../assests/images/images/germany.jpg';
import istanbul from '../../assests/images/images/istanbul.jpg';


export default class BrowseLocation extends Component {
    componentDidMount() {

    }
    render() {
        return (
            <section className="browser-location-ctn">
                <section className="browser-location-item1">
                    <h2 className="browser-location-item1-head">
                        Browse Location
                    </h2>
                    <div className="browser-location-item1-map-ctn">
                        <img src={map} alt="location-map" className="browser-location-item1-map"/>
                    </div>
                </section>
                
                <section className="browser-location-item3">
                    <figure className="browser-location-item3-img-ctn">
                        <div className="browser-location-item3-overlay">
                            <h3 className="browser-location-item3-overlay-txt">Bahrain</h3>
                        </div>
                      <img src={bahrain} alt="" className="browser-location-item3-img"/>  
                    </figure>
                   
                    <figure className="browser-location-item3-img-ctn">
                        <div className="browser-location-item3-overlay">
                            <h3 className="browser-location-item3-overlay-txt">Dubai</h3>
                        </div>
                        <img src={dubai} alt="" className="browser-location-item3-img"/>
                    </figure>
                    <figure className="browser-location-item3-img-ctn">
                        <div className="browser-location-item3-overlay">
                            <h3 className="browser-location-item3-overlay-txt">France</h3>
                        </div>
                        <img src={france} alt="" className="browser-location-item3-img" />
                    </figure>
                    <figure className="browser-location-item3-img-ctn">
                        <div className="browser-location-item3-overlay">
                            <h3 className="browser-location-item3-overlay-txt">Germany</h3>
                        </div>
                        <img src={germany} alt="" className="browser-location-item3-img" />
                    </figure>
                    <figure className="browser-location-item3-img-ctn">
                        <div className="browser-location-item3-overlay">
                            <h3 className="browser-location-item3-overlay-txt">Turkey</h3>
                        </div>
                        <img src={istanbul} alt="" className="browser-location-item3-img" />
                    </figure>
                </section>
            </section>
        )
    }
}
