import React from "react";
import automobile from "../../assests/images/images/automobile.png";
import banknote from "../../assests/images/images/banknote.png";
import bed from "../../assests/images/images/bed.png";
import book from "../../assests/images/images/book.png";
import flight from "../../assests/images/images/flight.png";
import globe from "../../assests/images/images/globe.png";
import handshake from "../../assests/images/images/handshake.png";
import man from "../../assests/images/images/man.png";
import trolly from "../../assests/images/images/trolly.png";
import cleaning from "../../assests/images/images/gloves_protective_icon.png";
import umbrella from "../../assests/images/images/umbrella.png";
import insurance from "../../assests/images/images/insurance.456x512.png";
import cook from "../../assests/images/images/restaurant_cooking_eating_food_kitchen_icon.png";
import babysitting from "../../assests/images/images/babysitting-100.png";
import gardening from "../../assests/images/images/gardening-100.png";
import ContactForm from "../conctact-us/ContactForm";
import SliderShow from "../corasol";

export default function ServicesBody() {
  let logos = [
    {
      src: globe,
      alt: "masalaty-travel-logo-globe",
      title: "Travel The World",
      des: "We will assist you to aquire travel documents from any immigration authority to travel the world, for Tourism, Studies & many more.",
    },
    {
      src: trolly,
      alt: "masalaty-travel-logo-trolly",
      title: "Work Permits Around The World",
      des: "Be opportune to travel and work in several countries through Maslaty Travel Agency.",
    },
    {
      src: book,
      alt: "masalaty-travel-logo-book",
      title: "Immigration & Passport Help",
      des: "We assist in acquiring international passports,  students admission letters abroad, tourist visas and proper documentations for would be immigrants",
    },
    {
      src: flight,
      alt: "masalaty-travel-logo-flight",
      title: "Flight Tickets",
      des: "Buy flight tickets from us to and fro at affordable prices. Reserve your seats with any airline of your choice through us.",
    },
    {
      src: bed,
      alt: "masalaty-travel-logo-hotel-reservation",
      title: "Hotel Reservation",
      des: "Do your hotel reservations with in economic and classical hotels around the world.",
    },
    {
      src: handshake,
      alt: "masalaty-travel-logo-airport-assistance",
      title: "Air Port Assistance",
      des: "Things are made easy for as we assist you at the airport for check ins and check outs.",
    },
    {
      src: man,
      alt: "masalaty-travel-logo-tourism",
      title: "Tour guides",
      des: "If you are visiting Cameroon we have well trained tour guides who are multilingual to accompany you through out your stay in Cameroon.",
    },
    {
      src: umbrella,
      alt: "masalaty-travel-logo-umbrella",
      title: "Travel Inssurance",
      des: "We can help you get travel insurance for studies, work, immigration or tourism in countries across the globe.",
    },
    {
      src: automobile,
      alt: "masalaty-travel-logo-automobile",
      title: "Car Rental",
      des: "We aid you rent cars from any part of Cameroon.",
    },
    {
      src: banknote,
      alt: "masalaty-travel-logo-money",
      title: "Currency Exchange",
      des: "Exchange Currencies with us if travelling out of Cameroon or if you just came into Cameroon. We do these exchanges at very affordable rates.",
    },
    {
      src: insurance,
      alt: "masalaty-travel-logo-money",
      title: "Insurance",
      des: "Exchange Currencies with us if travelling out of Cameroon or if you just came into Cameroon. We do these exchanges at very affordable rates.",
    },
    {
      src: flight,
      alt: "masalaty-travel-logo-money",
      title: "IATA (Aviation) Training",
      des: "Aviation training in all critical sectors: airline, airport, air navigation services, cargo, civil aviation, ground operations, safety, tourism and more.",
    },
    {
      src: cleaning,
      alt: "masalaty-travel-logo-money",
      title: "Cleaning Services",
      des: "General house cleaning services are best for customers who are too busy to tend to household chores. This service involves tasks like vacuum cleaning, mopping, dusting, floor polishing, bathroom cleaning, polishing mirrors, and the like.",
    },
    {
      src: cook,
      alt: "masalaty-travel-logo-money",
      title: "Domestic Cooking Services",
      des: "Our company provides best Cook/skilled artisans to prepare and serve your favorite food at very affordable prices or rate, Please try once and give us a chance.",
    },
    {
      src: babysitting,
      alt: "masalaty-travel-logo-money",
      title: "Baby Sitting",
      des: "A babysitter, or 'sitter', is a person paid to look after a family's child or children when the parents are away for a short time. Babysitters are often teenage females.",
    },
    {
      src: gardening,
      alt: "masalaty-travel-logo-money",
      title: "Gardening Services",
      des: "Gardening is an activity that is quite physical. It includes weeding, plant watering, mulching, trellising, and harvesting–all of which involve the gardener's physical labor. It, therefore, becomes a great complement to your workout routine.",
    },
  ];
  return (
    <section className="services-body">
      <header className="about-us__head">
        <h3 className="about-us__head--title">
          <span className="about-us__head--title--pry">Our Services</span>
          <span className="about-us__head--title--sec">
            <em>Home/Our Services</em>
          </span>
        </h3>
      </header>
      <main className="services-body">
        {logos.map((logo) => (
          <section className="services-body-items">
            <section className="services-body-items-logos-ctn">
              <img
                src={logo.src}
                alt={logo.alt}
                className="services-body-items-logos"
              />
              <span className="heading-secondary-subs services-body-title-txt services-body-items-logos-title">
                {logo.title}
              </span>
            </section>
            <p className="services-body-items-des">{logo.des}</p>
          </section>
        ))}
      </main>
      <SliderShow slide={3} />
      <ContactForm />
    </section>
  );
}
