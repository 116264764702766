import React, { Component } from "react";
import { Link } from "react-router-dom";
import logoLarge from "../../assests/images/logo-large.png";
import Icon from "../../svgs";

let navContent = [
  {
    to: "/",
    text: "Home",
    id: "homeLink",
    alt: "Masalaty Travel logo",
    src: logoLarge,
  },
  { to: "/about-us", text: "About Us", id: "aboutUsLink" },
  { to: "/services", text: "Services", id: "servicesLink" },
  {
    href: "https://www.facebook.com/Masalaty-Travel-Training-Center-121863233006031",
    text: "Blog",
    id: "blogLink",
  },
  { to: "/contact-us", text: "Contact Us", id: "contactLink" },
  /* { to: '/home', alt: "Masalaty Travel logo", id: "logoLinkFr", src: logo },
     {to: '/fr/accuiel', text: "Accuiel", id: "accuielLink"},
     {to: '/fr/nous', text: "Nous", id: 'nousLink'},
     {to: '/fr/service', text: "Service", id: 'servicesLink'},
     {href: '#', text: "Blog", id: 'blogLinkFr'},
     {to: '/fr/contacte-nous', text: "Contact", id: 'contactNousLink'},*/
];

export default class Navigation extends Component {
  componentDidMount() {
    let menu = document.getElementById("menuBtn"),
      topMenuBtn = document.getElementById("topMenuBtn"),
      exit = document.getElementById("exitBtn"),
      navLinks = document.querySelectorAll(".mobile-nav-links"),
      mobileNav = document.getElementById("mobileNav");

    topMenuBtn.addEventListener("click", () => {
      if (mobileNav.classList.contains("mobile-menu-animate")) {
        mobileNav.classList.remove("mobile-menu-animate");
      } else {
        mobileNav.classList.add("mobile-menu-animate");
      }
    });
    menu.addEventListener("click", () => {
      if (mobileNav.classList.contains("mobile-menu-animate")) {
        mobileNav.classList.remove("mobile-menu-animate");
      } else {
        mobileNav.classList.add("mobile-menu-animate");
      }
    });
    exit.addEventListener("click", () => {
      mobileNav.classList.remove("mobile-menu-animate");
    });
    navLinks.forEach((link) => {
      link.addEventListener("click", () => {
        mobileNav.classList.remove("mobile-menu-animate");
      });
    });

    function myFunction(x) {
      if (x.matches) {
        // If media query matches
        window.onscroll = function () {
          scrollFunction();
        };

        function scrollFunction() {
          document.getElementsByClassName("top-menu")[0].style.transition =
            "all .3s ease";
          if (
            document.body.scrollTop > 400 ||
            document.documentElement.scrollTop > 400
          ) {
            document.getElementsByClassName("top-menu")[0].style.top = "0";
          } else {
            document.getElementsByClassName("top-menu")[0].style.top = "-100px";
          }
        }
      } else {
        document.getElementsByClassName("top-menu")[0].style.top = "-100px";
      }
    }
    var x = window.matchMedia("(max-width: 1000px)");
    myFunction(x); // Call listener function at run time
    x.addListener(myFunction); // Attach listener function on state changes
  }
  render() {
    return (
      <>
        <section className="navigation-wrapper">
          <section className="mobile-nav" id="mobileNav">
            <button className="mobile-nav-btn-exit" id="exitBtn">
              <Icon class="mobile-nav-btn" id="icon-cross" />
            </button>
            {navContent.map((link, index) =>
              link.href ? (
                <a href={link.href} className="mobile-nav-links" key={index}>
                  {link.text}
                </a>
              ) : (
                <Link
                  className="mobile-nav-links"
                  to={link.to}
                  key={index}
                  id={link.id}
                >
                  {link.text}
                </Link>
              )
            )}
          </section>
        </section>

        <section className="nav">
          <Link to={navContent[0].to} className="logo-nav-ctn">
            <img
              src={navContent[0].src}
              alt={navContent[0].alt}
              className="logo-nav"
            />
          </Link>
          <nav className="nav-items-ctn">
            {navContent.map((link, index) =>
              link.href ? (
                <a href={link.href} className="nav-items" key={index}>
                  {link.text}
                </a>
              ) : (
                <Link to={link.to} className="nav-items" key={index}>
                  {link.text}
                </Link>
              )
            )}
            <button className="mobile-nav-btn-ctn" id="menuBtn">
              <Icon class="mobile-nav-btn" id="icon-menu" />
            </button>
          </nav>
          <section className="nav-contact">
            <div className="nav-contact-logo-ctn">
              <Icon class="nav-contact-icon" id="icon-phone" />
            </div>
            <div className="nav-contact-txt">
              <a href="tel:+237654199004" className="nav-contact-txt-num">
                <em>+(237) 654-199-004</em>
              </a>
              <div className="nav-contact-smd-ctn">
                <a
                  href="https://www.facebook.com/Masalaty-Travel-Training-Center-121863233006031"
                  className="nav-contact-smd-link"
                  title="like us on facebook"
                  target="_blank"
                >
                  <Icon class="nav-contact-smd" id="icon-facebook" />
                </a>
                <a
                  href="https://www.instagram.com/masalatytravel/"
                  className="nav-contact-smd-link"
                  title="follow us on instagram"
                  target="_blank"
                >
                  <Icon class="nav-contact-smd" id="icon-instagram" />
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=237654199004"
                  className="nav-contact-smd-link"
                  title="contact us on whatsapp"
                >
                  <Icon class="nav-contact-smd" id="icon-whatsapp" />
                </a>
              </div>
            </div>
          </section>
        </section>
        <section
          className="top-menu"
          style={{
            backgroundColor: "rgba(2, 166, 80, .95)",
            position: "fixed",
            paddingLeft: "2rem",
            paddingRight: "2rem",
            paddingBottom: "1rem",
            width: "100%",
            zIndex: 3000,
          }}
        >
          <div className="nav-contact-smd-ctn">
            <a
              href="https://www.facebook.com/Masalaty-Travel-Training-Center-121863233006031"
              className="nav-contact-smd-link"
              title="like us on facebook"
              target="_blank"
            >
              <Icon class="nav-contact-smd" id="icon-facebook" />
            </a>
            <a
              href="https://www.instagram.com/masalatytravel/"
              className="nav-contact-smd-link"
              title="follow us on instagram"
              target="_blank"
            >
              <Icon class="nav-contact-smd" id="icon-instagram" />
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=237654199004"
              className="nav-contact-smd-link"
              title="follow us on twitter"
            >
              <Icon class="nav-contact-smd" id="icon-whatsapp" />
            </a>
          </div>
          <button className="top-nav-btn-ctn" id="topMenuBtn">
            <Icon class="top-nav-btn" id="icon-menu" />
          </button>
        </section>
      </>
    );
  }
}
