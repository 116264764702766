import React from "react";
import IndexHead from "./IndexHead";
import BrowseLocation from "./BrowseLocation";
import WorldRotate from "./WorldRotate";
import WhyChooseUs from "./WhyChooseUs";
import MostPopDes from "./MostPopDes";
import Advert from "../Advert";
import ContactForm from "../conctact-us/ContactForm";
import SliderShow from "../corasol";
import { Helmet } from "react-helmet";

export default function Index() {
  return (
    <section>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Masalaty Travel Services</title>
        <meta
          name="keywords"
          content="masalaty travel agency, travel consultancy in cameroon, travel consultancy in buea, travel contancy douala, travel consultancy in yaounde, international travel agencies in cameroon, travel consultacy in bonaberi, travel agencies in douala, Masalaty Travel Agency, international Travel Agencies in Cameroon, Book flight tickets, Travel agencies in douala, travel agencies buea, travel agencies in younde, travel consultant in douala, travel consultant in yaounde, travel consult in cameroon, make car reservation in cameroon, make car reservation douala, flight ticketting in cameroon, study abraod from cameroon, travel to europe cameroon, travel to dubia, travel to america, travel to germany, play american lottery"
        />
      </Helmet>
      <IndexHead />
      <section className="browser-location">
        <BrowseLocation />
      </section>
      <WorldRotate />
      <WhyChooseUs />
      <MostPopDes />
      <ContactForm />
      <SliderShow slide={3} />
    </section>
  );
}
