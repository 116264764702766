import React from "react";
import TopBar from "./components/topbar/TopBar";
import { HashRouter, Route, Switch } from "react-router-dom";
import Navigation from "./components/topbar/Navigation";
import Index from "./components/index/Index";
import Footer from "./components/footer";
import ScrollToTop from "./components/scrolltop";
import ScrollApp from "./components/scrollToTopBtn";
import IndexServices from "./components/services/IndexServices";
import AboutUs from "./components/aboutus/about-us";
import Contact from "./components/conctact-us/contact";

function App() {
  return (
    <HashRouter>
      <ScrollToTop />
      <ScrollApp />
      <>
        <TopBar />
        <Navigation />
      </>
      <Switch>
        <Route exact path={"/"} component={Index} />
        <Route exact path={"/contact-us"} component={Contact} />
        <Route exact path={"/services"} component={IndexServices} />
        <Route exact path={"/about-us"} component={AboutUs} />
      </Switch>
      <Footer />
    </HashRouter>
  );
}

export default App;
