import React, {Component} from 'react';
import Icon from '../svgs';



/** React components for scrolling back to the top of the page **/

class ScrollButton extends Component {
    constructor() {
        super();
        this.state = {
            intervalId: 0,
            visibel: ''
        };
    }
    scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }

    scrollToTop() {
        let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
        this.setState({ intervalId: intervalId });
    }
    
    render() {
        return <button title='Back to top' className="scroll" onClick={() => { this.scrollToTop(); }}>
            <span className='arrow-up'>
                <Icon class='triangle-icon' id='icon-triangle' />
            </span>
        </button>;
    }
}

export default class ScrollApp extends React.Component {
    render() {
        return <ScrollButton scrollStepInPx="150" delayInMs="16.66" />
    }
}