import React, { Component } from 'react';
//importing city pics
import douala from '../../assests/masa/douala.jpg';
import berlin from '../../assests/masa/berlin.jpg';
import ankara from '../../assests/masa/ankara.jpg';
import brussel from '../../assests/masa/brussel.jpg';
import capeTown from '../../assests/masa/cape-town.jpg';
import dubia from '../../assests/masa/dubia.jpg';
import kuwait from '../../assests/masa/Kuwait-city.jpg';
import madrid from '../../assests/masa/madrid.jpg';
import manama from '../../assests/masa/manama.jpg';
import minks from '../../assests/masa/minks.jpeg';
import moscow from '../../assests/masa/moscow.jpg';
import newYork from '../../assests/masa/new-york.jpg';
import paris from '../../assests/masa/Paris.jpg';
import sar from '../../assests/masa/SAR.jpg';
import toronto from '../../assests/masa/toronto.jpg';
import ukrain from '../../assests/masa/ukrain.jpg';
import khazag from '../../assests/masa/Khazagstain.jpg';
//onfield

export default class MostPopDes extends Component {
    render() {
        const travelDes = [
            { src: ankara, country: 'Turkey' }, { src: berlin, country: 'Germany' }, { src: brussel, country: 'Belgium' }, { src: capeTown, country: 'South Africa' }, { src: douala, country: 'Cameroon' }, { src: dubia, country: 'UAE' }, { src: kuwait, country: 'Kuwait' }, { src: madrid, country: 'Spain' }, { src: manama, country: 'Bahrain' }, { src: paris, country: 'France' }, { src: toronto, country: "Canada" },
            { src: minks, country: 'Belarus' }, { src: moscow, country: 'Russia' }, { src: newYork, country: 'USA' }, { src: sar, country: "Saudi Arabia" }, { src: ukrain, country: "Ukrain" }, { src: khazag, country: "Khazagstain" }
        ];
        return (
            <section className="section-about">
                
                    <div className="row">
                        <div class="top--sells">
                            <h3 className="browser-location-item1-head">
                                Our Most Popular Travel and Visa Assistance Countries
						    </h3>
                            <div className="travel__des">
                                {
                                    travelDes.map(country =>
                                        <figure className="travel__des--ctn">
                                            <img src={country.src} alt="country" key={country.scr} className="travel__des--ctn--img" />
                                            <figcaption className="travel__des--ctn--name">
                                                {country.country}
                                            </figcaption>
                                        </figure>
                                    )
                                }
                            </div>
                        </div>
                    </div>
            </section>
        )
    }
}
