import React, { Component } from 'react';
import afriair from '../../assests/masa/afriair.jpg';
import airdelta from '../../assests/masa/airdelta.jpg';
import airfrance from '../../assests/masa/airfrance.jpg';
import belair from '../../assests/masa/belair.jpg';
import britshair from '../../assests/masa/britshair.jpg';
import egyptair from '../../assests/masa/egyptair.jpg';
import emirateair from '../../assests/masa/emirateair.jpg';
import kair from '../../assests/masa/kair.jpg';
import klmair from '../../assests/masa/klmair.jpg';
import ufair from '../../assests/masa/lufair.jpg';
import morocair from '../../assests/masa/morocair.jpg';
import qatarair from '../../assests/masa/qatarair.jpg';
import virginnija from '../../assests/masa/virginnija.jpg';
import etihad from '../../assests/masa/etihad.png';
import rwanderair from '../../assests/masa/rwanderair.png';
import saair from '../../assests/masa/saair.jpg';
import masaComic from '../../assests/masa/masa-comic.jpeg';
import worldImg from '../../assests/images/images/world-img.png';
import turkish from '../../assests/masa/turkish-logo.jpg';
import camair from '../../assests/masa/camair.webp';
import brussel from '../../assests/masa/brussel-logo.webp';
import skyair from '../../assests/masa/sky-airline.jpg';
import massa13 from '../../assests/masa/massa13.jpeg';
import masalaty1 from '../../assests/images/masalaty1.mp4'

export default class WorldRotate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            airlines: [
                { /*src: camair, key: 1, alt: "move plane"*/ },
                { src: afriair, key: 2, alt: "africa airline" },
                { src: airdelta, key: 3, alt: "air delta" },
                { src: airfrance, key: 4, alt: "air france" },
                { src: belair, key: 5, alt: "bel air" },
                { src: britshair, key: 6, alt: "british air line" },
                { src: egyptair, key: 7, alt: "egypt air line" },
                { src: emirateair, key: 8, alt: "emirate air" },
                { src: kair, key: 9, alt: "kenyan air line" },
                { src: klmair, key: 10, alt: "klm air line" },
                { src: ufair, key: 11, alt: "uf air" },
                { src: qatarair, key: 12, alt: "qatar air line" },
                { src: virginnija, key: 13, alt: "virgin nigeria" },
                { src: morocair, key: 14, alt: "virgin nigeria" },
                { src: etihad, key: 15, alt: "virgin nigeria" },
                { src: rwanderair, key: 16, alt: "virgin nigeria" },
                { src: saair, key: 17, alt: "South Africa airline" },
                { src: turkish, key: 17, alt: "turkish airline" },
                { src: camair, key: 17, alt: "Camair-co airline" },
                { src: brussel, key: 17, alt: "brussel airline" },
                { src: skyair, key: 17, alt: "Sky airline" },
                
            ],
        }
    }
    render() {
        return (
            <section className="world-rotate">
                <section className="world-rotate__air--advt">
                    {
                        this.state.airlines.map(airline =>
                            <img src={airline.src} key={airline.key} alt={airline.alt} className="air-advt" />
                        )
                    }
                </section>
                <section className="section-about">
                    <div className="u-center-text u-margin-bottom-small">
                        <h2 className="browser-location-item1-head">
                            Welcome To Masalaty Travel <br />Agency & Training Center
                            <h5 className="heading-secondary-subs">
                                The Best Way to Fly
                            </h5>
                        </h2>
                    </div>
                    <div className="row">
                        <div className="about-us__home">
                            <div>
                                <h3 className="heading-tertiary u-margin-bottom-small">
                                    Who We Are
						    </h3>

                                <div className="paragraph">
                                    <p>
                                        At Masalaty Travel Agency & Training Center, we have Experts that will save you time while offering exclusive value for your dream immigration or vacation. Which ever country you dream to take a vacation too or you desire to immigrate too <strong><mark>It Is Our Job To Bring You There!</mark></strong>
                                    </p>
                                    <p>
                                        We are a licience registerd travel agency under the Douala metropolitant city council with Tax payer card N<sup>o</sup> <em>Ref 123456789</em>
                                    </p>
                                </div>
                                <div className="onfield">
                                    <img src={massa13} alt="masalaty staff" />
                                </div>
                            </div>
                            <div className="onfield">
                                <div className="world-img">
                                   <img src={worldImg} alt="plane take off" /> 
                                </div>
                                <p className="travel-world">
                                    <h3 className="travel-world-1">
                                        Travel Through Our Amazing
                                    </h3>
                                    <h3 className="travel-world-2">
                                       WORLD
                                    </h3> 
                                    <h3 className="travel-world-3">
                                        With MASALATY
                                    </h3>
                                </p>
                            </div>
                            <div className="about-us__home">
                                    <div className="latest__news--ctnr">
                                    <video className='advert-video'  controls>
                                        <source src={masalaty1} type="video/mp4" />
                                            <source src="movie.ogg" type="video/ogg" />
                                    </video>
                                    </div>
                                <div class="fb-page" data-href="https://www.facebook.com/Masalaty-Travel-Training-Center-121863233006031" data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/Masalaty-Travel-Training-Center-121863233006031" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/Masalaty-Travel-Training-Center-121863233006031">Masalaty Travel Agency &amp; Training Center</a></blockquote></div>
                                </div>
                                </div>
                            </div>
                        
                </section>
            </section>
        )
    }
}
