import React from 'react';
import ServicesHead from './ServicesHead';
import ServicesBody from './ServicesBody';
import {Helmet} from "react-helmet";

export default function IndexServices() {
    return (
        <section className="services">
            <Helmet>
                <meta charSet='utf-8' />
                <title> Masalaty Travel Services</title>
                <meta name="keywords" content="masalaty travel agency, travel consultancy in cameroon, travel consultancy in buea, travel contancy douala, travel consultancy in yaounde, international travel agencies in cameroon, travel consultacy in bonaberi, travel agencies in douala, Masalaty Travel Agency, international Travel Agencies in Cameroon, Book flight tickets, Travel agencies in douala, travel agencies buea, travel agencies in younde, travel consultant in douala, travel consultant in yaounde, travel consult in cameroon, make car reservation in cameroon, make car reservation douala, flight ticketting in cameroon, study abraod from cameroon, travel to europe cameroon, travel to dubia, travel to america, travel to germany, play american lottery" />
            </Helmet>
            <ServicesHead title= "Masalaty @ Your Service" />
            <ServicesBody />
        </section>
    )
}

