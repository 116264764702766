import React, { Component } from "react";
import img1 from "../../assests/images/images/plain1.jpg";
import img2 from "../../assests/images/images/img3.jpg";
import img3 from "../../assests/images/images/img5.jpg";
import img4 from "../../assests/images/images/img4.jpg";
import img5 from "../../assests/images/images/exchange2.jpg";
import { NavLink } from "react-router-dom";

export default class IndexHead extends Component {
  constructor() {
    super();
    this.state = {
      imageSrc: img1,
    };
  }
  componentDidMount() {
    let i = 0;
    setTimeout(() => {
      setInterval(() => {
        i += 1;
        if (i === 1) {
          this.setState(() => {
            return {
              imageSrc: img2,
            };
          });
        } else if (i === 2) {
          this.setState(() => {
            return {
              imageSrc: img3,
            };
          });
        } else if (i === 3) {
          this.setState(() => {
            return {
              imageSrc: img4,
            };
          });
        } else if (i === 4) {
          this.setState(() => {
            return {
              imageSrc: img5,
            };
          });
        } else if (i === 5) {
          i = 0;
          this.setState(() => {
            return {
              imageSrc: img1,
            };
          });
        }
      }, 3000);
    }, 1000);
  }
  render() {
    return (
      <section className="index-head">
        <section className="index-head-images-ctn">
          <img
            src={this.state.imageSrc}
            alt={"travel agencies in douala"}
            className="index-head-images"
          />
        </section>
        <section className="index-head-txt-ctn">
          <h1 className="index-head-txt-title">MASALATY TRAVEL</h1>
          <h2 className="index-head-txt-title-sub">
            <span className="index-head-txt-title-sub-span"></span>
            <span
              className="index-head-txt-title-sub-span-txt"
              style={{ fontWeight: "bold" }}
            >
              Book Flights, Travel Consultancy, Tour Guide{" "}
            </span>
            <span className="index-head-txt-title-sub-span"></span>
          </h2>
          <h2 className="index-head-txt-title-sub">
            <span className="index-head-txt-title-sub-span"></span>
            <span
              className="index-head-txt-title-sub-span-txt"
              style={{ fontWeight: "bold" }}
            >
              Car Rental, Cleaning Services Insurance, Clearing Agent
            </span>
            <span className="index-head-txt-title-sub-span"></span>
          </h2>
          <h2 className="index-head-txt-title-sub">
            <span className="index-head-txt-title-sub-span"></span>
            <span
              className="index-head-txt-title-sub-span-txt"
              style={{ fontWeight: "bold" }}
            >
              Domestic Cooking, Passport Assistance Services
            </span>
            <span className="index-head-txt-title-sub-span"></span>
          </h2>
          <h2 className="index-head-txt-title-sub">
            <span className="index-head-txt-title-sub-span"></span>
            <span
              className="index-head-txt-title-sub-span-txt"
              style={{ fontWeight: "bold" }}
            >
              Currency Exchange, Airport Assistant, Baby Sitting
            </span>
            <span className="index-head-txt-title-sub-span"></span>
          </h2>
          <h2 className="index-head-txt-title-sub">
            <span className="index-head-txt-title-sub-span"></span>
            <span
              className="index-head-txt-title-sub-span-txt"
              style={{ fontWeight: "bold" }}
            >
              Hotel Reservation, Gardening Services,
            </span>
            <span className="index-head-txt-title-sub-span"></span>
          </h2>
          <NavLink
            to={"/contact-us"}
            className="btn-link btn-color-white btn-home-contact"
          >
            Contact Us
          </NavLink>
        </section>
      </section>
    );
  }
}
