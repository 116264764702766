import React, { Component } from 'react';
import {navLink, NavLink} from 'react-router-dom';
import automobile from '../../assests/images/images/automobile.png';
import banknote from '../../assests/images/images/banknote.png';
import bed from '../../assests/images/images/bed.png';
import book from '../../assests/images/images/book.png';
import flight from '../../assests/images/images/flight.png';
import globe from '../../assests/images/images/globe.png';
import handshake from '../../assests/images/images/handshake.png';
import man from '../../assests/images/images/man.png';
import trolly from '../../assests/images/images/trolly.png';
import watch from '../../assests/images/images/watch.png';
import whyus from '../../assests/images/images/whyus.png';

export default class WhyChooseUs extends Component {
    render() {
        let logos = [
            {src: globe, alt: 'masalaty-travel-logo-globe', title: 'Travel The World', des: 'We will assist you to aquire travel documents from any immigration authority to travel the world, for Tourism, Studies & many more.'},
            {src: trolly, alt: 'masalaty-travel-logo-trolly', title: 'Work Permits Around The World', des: 'Be opportune to travel and work in several countries through Maslaty Travel Agency.'},
            {src: book, alt: 'masalaty-travel-logo-book', title: 'Immigration & Passport Help', des: 'We assist in acquiring international passports,  students admission letters abroad, tourist visas and proper documentations for would be immigrants'},
            {src: flight, alt: 'masalaty-travel-logo-flight', title: 'Flight Tickets', des: 'Buy flight tickets from us to and fro at affordable prices. Reserve your seats with any airline of your choice through us.'},
            {src: bed, alt: 'masalaty-travel-logo-hotel-reservation', title: 'Hotel Reservation', des: 'We have partnership with many economic and first class hotels around the globe. So book your hotel reservations through us at affordable prices.'},
            {src: man, alt: 'masalaty-travel-logo-tourism', title: 'Tour guides', des: 'If you are visiting Cameroon we have well trained tour guides who are multilingual to accompany you through out your stay in Cameroon.'},
            {src: automobile, alt: 'masalaty-travel-logo-car', title: 'Car Rental', des: 'We aid you rent cars from any part of Cameroon.'},
            {src: banknote, alt: 'masalaty-travel-logo-money', title: 'Currency Exchange', des: 'Exchange Currencies with us if travelling out of Cameroon or if you just came into Cameroon. We do these exchanges at very affordable rates.'},
        ]
        return (
            
            <sction className="why-choose-us">
                <section className="why-choose-us-overlay">
                    <section className="why-choose-us-1">
                        <h2 className="why-choose-us-title">
                            <span className="browser-location-item1-head why-choose-us-title-txt">Why Us?</span><span className="why-choose-us-title-txt-sub-span"></span>
                            <p className="heading-secondary-subs why-choose-us-title-txt-size">We Make all these Processes Easy</p>
                        </h2>
                        {
                            logos.map(logo => 
                                logo.src === globe || logo.src === book || logo.src === trolly || logo.src === flight?
                                    <section className="why-choose-us-items">
                                        <section className="why-choose-us-items-logos-ctn">
                                            <img src={logo.src} alt={logo.alt} className="why-choose-us-items-logos"/>
                                        <span className="heading-secondary-subs why-choose-us-title-txt why-choose-us-items-logos-title">
                                                {
                                                    logo.title
                                                }
                                            </span>
                                        </section>
                                    <p className="why-choose-us-items-des">
                                        {
                                            logo.des
                                        }
                                    </p>
                                    </section>: null
                                )
                        }
                    <NavLink to={'/services'} className="btn-link btn-color-white">
                        More Services
                    </NavLink>
                    </section> 
                    <section className="why-choose-us-2">
                        <img src={whyus} alt="why-us-masalaty"/>
                        <section></section>
                    </section>
                </section>
                
            </sction>
        )
    }
}
