import React from "react";
import axios from "axios";
import Icon from "../../svgs";

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.sendMessage = this.sendMessage.bind(this);
    this.state = {
      name: "",
      email: "",
      contact: "",
      subject: "",
      message: "",
      successStatus: "",
      style: "",
    };
  }
  sendMessage(templateId, variables) {
    window.emailjs
      .send("gmail", templateId, variables)
      .then((res) => {
        this.setState(() => {
          return {
            successStatus: "Message Sent Successful",
            name: "",
            email: "",
            contact: "",
            subject: "",
            message: "",
          };
        });
      })
      // Handle errors here however you like, or use a React error boundary
      .catch((err) => {
        console.log(err);
        this.setState(() => {
          return {
            successStatus: "Message Sent Fail",
          };
        });
      });
  }

  handleSubmit = () => {
    const templateId = "user_NgUKo5ZkKt6vKZtAdqkQW";

    this.sendMessage(templateId, {
      message: this.state.message,
      from_name: this.state.name,
      from_email: this.state.email,
      from_contact: this.state.contact,
      from_subject: this.state.subject,
      reply_to: this.state.email,
    });
  };

  render() {
    return (
      <>
        <header className="about-us__head">
          <h3 className="about-us__head--title">
            <span className="about-us__head--title--pry">Contact Us</span>
            <span className="about-us__head--title--sec">
              <em>Home/Contact-Us</em>
            </span>
          </h3>
        </header>
        <section className="contact">
          <section className="contact__addr">
            <p className="contact__addr--title">Address</p>
            <p
              className=""
              style={{ marginBottom: "1.5rem", marginTop: ".5rem" }}
            >
              <span className="contact__addr--des-icon">
                <i class="fas fa-map-marked-alt"></i>
              </span>
              <span className="contact__addr--des-des">
                General Express Bonaberi, Douala - Cameroon
              </span>
              <span className="contact__addr--des-des">
                172 Maltzan Street, Pretoria West, Pretoria South Africa
              </span>
            </p>
            <p className="contact__addr--title">Opening Hours</p>
            <p
              className=""
              style={{ marginBottom: "1.5rem", marginTop: ".5rem" }}
            >
              <span className="contact__addr--des-icon">
                <i class="fas fa-clock"></i>
              </span>
              <span className="contact__addr--des-des">
                Week days 8:00 - 20:00
              </span>
            </p>
            <p className="contact__addr--title">Phone</p>
            <p
              className=""
              style={{ marginBottom: "1.5rem", marginTop: ".5rem" }}
            >
              <a href="tel:+237654199004" className="contact-ancor">
                <span className="contact__addr--des-icon">
                  <i class="fas fa-phone"></i>
                </span>
                <span className="contact__addr--des-des">
                  + (237) 654-199-004
                </span>
              </a>
            </p>
            <p className="contact__addr--title">Whatsapp</p>
            <p
              className=""
              style={{ marginBottom: "1.5rem", marginTop: ".5rem" }}
            >
              <a
                href="https://api.whatsapp.com/send?phone=237654199004"
                className="contact-ancor"
              >
                <span className="contact__addr--des-icon">
                  <Icon class="icon-contact-whatsapp" id="icon-whatsapp" />
                </span>
                <span className="contact__addr--des-des">
                  + (237) 654-199-004
                </span>
              </a>
            </p>
            <p className="contact__addr--title">Email</p>
            <p
              className=""
              style={{ marginBottom: "1.5rem", marginTop: ".5rem" }}
            >
              <a
                href="mailto:masalatytravel84@gmail.com"
                className="contact__addr--des-icon"
              >
                <i class="fas fa-at"></i>
              </a>
              <span className="contact__addr--des-des">
                <a
                  style={{ color: "black", textDecoration: "none" }}
                  href="mailto:masalatytravel84@gmail.com"
                >
                  masalatytravel84@gmail.com
                </a>
              </span>
            </p>
          </section>
          {/* <div className="contact__ctn">
            <h2 className="contact__ctn-title ">Contact Us</h2>
            <form
              action="#"
              name="contactform"
              id="contactForm"
              className="contact__ctn-form"
            >
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Contact Name"
                onChange={(e) => this.setState({ name: e.target.value })}
                required
                value={this.state.name}
              />
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Contact Email"
                required
                onChange={(e) => this.setState({ email: e.target.value })}
                value={this.state.email}
              />
              <input
                type="tel"
                name="phone"
                id="phone"
                placeholder="Contact Phone"
                required
                onChange={(e) => this.setState({ contact: e.target.value })}
                value={this.state.contact}
              />
              <input
                type="text"
                name="service"
                id="service"
                required
                onChange={(e) => this.setState({ subject: e.target.value })}
                placeholder="subject"
                value={this.state.subject}
              />
              <textarea
                name="contactreason"
                id="contactReason"
                cols="30"
                rows="10"
                required
                onChange={(e) => this.setState({ message: e.target.value })}
                value={this.state.message}
              />
              <button
                type="submit"
                name="submit"
                id="submit"
                className="btn contact-btn-new"
                onClick={(e) => {
                  e.preventDefault();
                  this.handleSubmit();
                  this.setState(() => {
                    return {
                      style: {
                        display: "block",
                      },
                    };
                  });
                }}
              >
                SENT MESSAGE
              </button>
              {this.state.successStatus &&
              this.state.successStatus === "Message Sent" ? (
                <p
                  className={"success-message"}
                  style={this.state.style}
                  onClick={() => {
                    this.setState(() => {
                      return {
                        successStatus: "",
                        style: {
                          display: "none",
                        },
                      };
                    });
                  }}
                >
                  {this.state.successStatus}
                </p>
              ) : this.state.successStatus &&
                this.state.successStatus === "Message Sent Fail" ? (
                <p
                  className={"fail-message"}
                  style={this.state.style}
                  onClick={() => {
                    this.setState(() => {
                      return {
                        style: {
                          display: "none",
                        },
                      };
                    });
                  }}
                >
                  {this.state.successStatus}
                </p>
              ) : null}
            </form>
          </div> */}
        </section>
      </>
    );
  }
}
