import React, { Component } from "react";
import { NavLink } from "react-router-dom";

const Footer = (props) => {
  let menuItems = [
    { to: "/", text: "Home", id: "homeLink" },
    { to: "/about-us", text: "About Us", id: "aboutUsLink" },
    { to: "/services", text: "Services", id: "servicesLink" },
    { to: "/contact-us", text: "Contact Us", id: "contactLink" },
  ];
  return (
    <footer className="footer">
      <div className="footer__main">
        <div className="footer__main--grid">
          <p className="footer__main--grid--tle">Quick Access</p>
          {menuItems.map((value) => (
            <NavLink
              to={value.to}
              className="footer__main--grid--anchor"
              key={value.id}
            >
              {value.text}
            </NavLink>
          ))}
        </div>
        <div className="footer__main--grid">
          <p className="footer__main--grid--tle">Main Office Address</p>
          <p className="footer__main--grid--ctn">
            <strong>
              MasalaTy Travel Agency & Training Center is situated @
            </strong>
            <br />
            <address>
              General Express Bonaberi
              <br />
              Douala IV, Litoral Cameroon <br />
              <i className="fas fa-phone fa-1x"></i>{" "}
              <a href="tel:+237654199004">
                <em>(+237)654-199-004</em>
              </a>
              <br />
              <a
                href="mailto:masalatytravel84@gmail.com
"
              >
                {" "}
                <i className="fas fa-mail-bulk fa-1x"></i>{" "}
                <em>masalatytravel84@gmail.com</em>
              </a>
            </address>
          </p>
        </div>
        <div className="footer__main--grid">
          <p className="footer__main--grid--tle">South Africa Office Address</p>
          <p className="footer__main--grid--ctn">
            <strong>
              MasalaTy Travel Agency & Training Center is situated @
            </strong>
            <br />
            <address>
              172 Maltzan Street
              <br />
              Pretoria West, Pretoria South Africa <br />
              <i className="fas fa-phone fa-1x"></i>{" "}
              <a href="tel:+237654199004">
                <em>(+237)654-199-004</em>
              </a>
              <br />
              <a
                href="mailto:masalatytravel84@gmail.com
"
              >
                {" "}
                <i className="fas fa-mail-bulk fa-1x"></i>{" "}
                <em>masalatytravel84@gmail.com</em>
              </a>
            </address>
          </p>
        </div>
        <div className="footer__main--grid">
          <p className="footer__main--grid--tle"> Connect With Us</p>
          <p className="socail__media">
            <a
              href="https://www.facebook.com/Masalaty-Travel-Training-Center-121863233006031"
              target="_blank"
              className="socail__media--list"
            >
              <i class="fab fa-facebook fa-3x"></i>
            </a>
            <a
              href="https://www.instagram.com/masalatytravel/"
              target="_blank"
              className="socail__media--list"
            >
              <i className="fab fa-instagram-square fa-3x"></i>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=237654199004"
              target="_blank"
              className="socail__media--list"
            >
              <i className="fab fa-whatsapp fa-3x"></i>
            </a>
          </p>
        </div>
      </div>
      <p className="footer__signature">
        &#169;{new Date().getFullYear()} MASALATY TRAVEL ANGENCY & TRAINING
        CENTER <br />
        <em>Website Design and Develop By Menorah Tech House</em>
      </p>
    </footer>
  );
};

export default Footer;
