import React, { Component } from "react";
import Icon from "../../svgs";
import "./top-bar.css";

export default class TopBar extends Component {
  render() {
    return (
      <section className="top-head">
        <a
          href="mailto:masalatytravel84@gmail.com"
          className="top-head-child"
          style={{ textDecoration: "none" }}
        >
          <Icon class="top-head-icon-child" id="icon-envelope" />
          <span className="anchor top-head-child-anchor text typo-grey">
            masalatytravel84@gmail.com
          </span>
        </a>
        <div className="top-head-child text typo-grey">
          <Icon class="top-head-icon-child" id="icon-location" /> General
          Express Bonaberi, Douala - Cameroon
        </div>
        <div className="top-head-child text typo-grey"></div>
        <div className="top-head-child text typo-grey">
          <Icon class="top-head-icon-child" id="icon-location" /> 172 Maltzan
          Street, Pretoria West, Pretoria South Africa
        </div>
        <div className="top-head-child">
          <a
            href="https://api.whatsapp.com/send?phone=237654199004"
            className="top-head-child"
            style={{ textDecoration: "none" }}
          >
            <Icon class="top-head-icon-child" id="icon-whatsapp" />
            <span className="anchor top-head-child-anchor text typo-grey">
              + (237) 654-199-004
            </span>
          </a>
        </div>
      </section>
    );
  }
}
