import React, { Component } from 'react';
import Slider from 'infinite-react-carousel';
import massa2 from '../assests/masa/masa-boss.jpeg';
import massa14 from '../assests/masa/masa-boss4.jpeg';
//import massa15 from '../../build/static/media/masa-boss.33ea4ef6.jpeg';
import massa1 from '../assests/masa/massa1.jpeg';
import massa10 from '../assests/masa/massa10.jpeg';
import massa11 from '../assests/masa/massa11.jpeg';
import massa12 from '../assests/masa/massa12.jpeg';
import massa13 from '../assests/masa/massa13.jpeg';
import massa3 from '../assests/masa/massa3.jpeg';
import massa4 from '../assests/masa/massa4.jpeg';
//import massa5 from '../assests/masa/massa5.jpeg';
import massa6 from '../assests/masa/massa6.jpeg';
import massa7 from '../assests/masa/massa7.jpeg';
//import massa8 from '../assests/masa/massa8.jpeg';
import massa9 from '../assests/masa/massa9.jpeg';
import massa16 from '../assests/masa/masa-client.jpeg';
import massa17 from '../assests/masa/masa-client-1.jpeg';
//import massa18 from '../../build/static/media/masa-client-1.0c810371.jpeg';
//import massa23 from '../../build/static/media/masa-client.9871053e.jpeg';
import massa19 from '../assests/masa/masa-comic.jpeg';
//import massa20 from '../../build/static/media/masa-comic.f6b6ddfb.jpeg';
import massa21 from '../assests/masa/masa-girls.jpeg';
import massa22 from '../assests/masa/masa-staff.jpeg';



export default class SliderShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalSlide : this.props.slide
        }
    }
    changeQnty = (value) => {
        this.setState({
            totalSlide: value,
        });
    }
    componentWillMount() {
        window.addEventListener('load', () => {
            if(window.outerWidth < 650) {
                this.changeQnty(1)
            }
            if(window.outerWidth >= 650) {
                this.changeQnty(3)
            }
        }) 
        window.addEventListener('hashchange', () => {
            if(window.outerWidth < 650) {
                this.changeQnty(1)
            }
            if(window.outerWidth >= 650) {
                this.changeQnty(3)
            }
        })
        var rotate = window.matchMedia('(min-width: 650px)');
        rotate.addListener(() => {
            if (rotate.matches && this.state.totalSlide === 1) {
                this.changeQnty(3)
            }
            else if (rotate.matches && this.state.totalSlide === 3) {
                this.changeQnty(3)
            }
        })
        var mobile = window.matchMedia('(max-width: 649px)');
        rotate.addListener(() => {
            if (mobile.matches && this.state.totalSlide === 1) {
                this.changeQnty(1)
            }
            else if (mobile.matches && this.state.totalSlide === 3) {
                this.changeQnty(1)
            }
        })
    }
    
    render() {
        
        const images = [
            {src: massa1, alt: "masalaty travel agency"},
            {src: massa2, alt: "masalaty travel agency"},
            {src: massa3, alt: "masalaty travel agency"},
            {src: massa4, alt: "masalaty travel agency"},
            //{src: massa5, alt: "masalaty travel agency"},
            {src: massa6, alt: "masalaty travel agency"},
            {src: massa7, alt: "masalaty travel agency"},
            //{src: massa8, alt: "masalaty travel agency"},
            {src: massa9, alt: "masalaty travel agency"},
            {src: massa10, alt: "masalaty travel agency"},
            {src: massa11, alt: "masalaty travel agency"},
            {src: massa12, alt: "masalaty travel agency"},
            {src: massa13, alt: "masalaty travel agency"},
            {src: massa14, alt: "masalaty travel agency"},
            //{src: massa15, alt: "masalaty travel agency"},
            {src: massa16, alt: "masalaty travel agency"},
            {src: massa17, alt: "masalaty travel agency"},
            //{src: massa18, alt: "masalaty travel agency"},
            {src: massa19, alt: "masalaty travel agency"},
            //{src: massa20, alt: "masalaty travel agency"},
            {src: massa21, alt: "masalaty travel agency"},
            {src: massa22, alt: "masalaty travel agency"},
            //{src: massa23, alt: "masalaty travel agency"},
            
        ];

        let settings = {
            arrows: false,
            autoplay: true,
            gutter: 20,
            centerPadding: 10,
            initialSlide: true,
            slidesToShow: this.state.totalSlide,
            virtualList: true,
            wheel: true,
        };
        return (
            <div>
                <Slider {...settings} className="corasel-ctn">
                    {
                        images.map(image => 
                        <div className="corasel-slide">
                            <img src={image.src} alt={image.alt} className="corasel-img"/>
                        </div>
                        )
                    }
                </Slider>
            </div>
        );
    }
}