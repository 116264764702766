import React, { Component } from "react";
import SliderShow from "../corasol";
import masaStaff from "../../assests/masa/masa-staff.jpeg";
import masaBoss from "../../assests/masa/masa-boss.jpeg";
import masaBoss1 from "../../assests/masa/masa-boss4.jpeg";
import masaClient from "../../assests/masa/masa-client.jpeg";
import masaClient1 from "../../assests/masa/masa-client-1.jpeg";
import plain from "../../assests/masa/plain4.png";
import ServicesHead from "../services/ServicesHead";
import ContactForm from "../conctact-us/ContactForm";
import { Helmet } from "react-helmet";

const AboutUs = (props) => {
  const work = [
    { src: masaBoss, alt: "masalaty on job", key: 1 },
    { src: masaStaff, alt: "masalaty staff", key: 2 },
    { src: masaBoss1, alt: "masalaty on job", key: 3 },
    { src: masaClient, alt: "masalaty at airport", key: 4 },
    { src: plain, alt: "plain", key: 5 },
    { src: masaClient1, alt: "masalaty at airport", key: 6 },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> About Masalaty Travel Agency</title>
        <meta
          name="keywords"
          content="masalaty travel agency, travel consultancy in cameroon, travel consultancy in buea, travel contancy douala, travel consultancy in yaounde, international travel agencies in cameroon, travel consultacy in bonaberi, travel agencies in douala, Masalaty Travel Agency, international Travel Agencies in Cameroon, Book flight tickets, Travel agencies in douala, travel agencies buea, travel agencies in younde, travel consultant in douala, travel consultant in yaounde, travel consult in cameroon, make car reservation in cameroon, make car reservation douala, flight ticketting in cameroon, study abraod from cameroon, travel to europe cameroon, travel to dubia, travel to america, travel to germany, play american lottery"
        />
      </Helmet>
      <ServicesHead title="About Masalaty Travel" />
      <header className="about-us__head">
        <h3 className="about-us__head--title">
          <span className="about-us__head--title--pry">About Us</span>
          <span className="about-us__head--title--sec">
            <em>Home/About-Us</em>
          </span>
        </h3>
      </header>
      <main className="about-us__main">
        <p className="about-us__main--ctn">
          <strong>MasalaTy Travel Agency and Trainig</strong> is built on the
          premise of providing superior customer service to ensure our customers
          enjoy the best vacation/imigration possible at the best value. Our
          travel consultants have over 50 years’ combined travel experience,
          with personal visits to most areas of the world. We know the helpful
          hints for finding you the best value for your immigration/vacation.
          Each of us has a passion for travel and we look forward to helping you
          plan the vacation of your dreams, or making sure your business trip
          goes as planned.
          <br />
          <br />
          The CEO <strong>Ashu Emmanuel</strong> is well travel personnality who
          has been to Europe, middle and Eatern Asia and many countries in
          Africa.
        </p>
      </main>
      <SliderShow slide={3} />
      <ContactForm />
    </>
  );
};

export default AboutUs;
